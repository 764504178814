// Estilos para o Modal
export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  minWidth: "800px",
  maxWidth: "95vw",
  maxHeight: "95vh", // Limita altura máxima
  bgcolor: "#fff",
  borderRadius: "12px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  p: 2,
};

// Estilos para o container da tabela
export const tableContainerStyles = {
  maxHeight: "calc(90vh - 200px)", // Altura máxima considerando outros elementos
  overflow: "auto",
  borderRadius: "8px",
  border: "1px solid #e5e7eb",
};

// Estilos para a tabela
export const tableStyles = {
  width: "100%",
  borderCollapse: "separate", // Importante para sticky header
  borderSpacing: 0,
};

// Estilos para o cabeçalho
export const thStyles = {
  padding: "8px",
  textAlign: "left",
  fontSize: "0.775rem",
  fontWeight: 700,
  color: "#374151",
  backgroundColor: "#f9fafb",
  position: "sticky",
  top: 0,
  zIndex: 1,
  borderBottom: "1px solid #e5e7eb",
  whiteSpace: "nowrap", // Evita quebra de linha no cabeçalho
};

export const tdStyles = {
  padding: "4px 12px",
  fontSize: "0.775rem",
  color: "#374151",
  borderBottom: "1px solid #e5e7eb",
  backgroundColor: "inherit",
};

// Estilos componentes estilizados existentes
export const Legends = {
  display: "flex",
  alignItems: "center",
  marginBottom: "16px",
};

export const Student = {
  fontWeight: 600,
  fontSize: "0.875rem",
  color: "#1f2937",
};
