import React, { useEffect, useState } from "react";
import { Container, ContainerBox } from "./styles";
import {
  Alert,
  Autocomplete,
  Button,
  Snackbar,
  TextField,
} from "@mui/material";
import { sendDocuments } from "../../api/autenticoAPI";
import { Loading } from "../Loading/Loading";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SendIcon from "@mui/icons-material/Send";
import { getAllStudents } from "../../api/sponteAPI";
import {
  AttachmentsArea,
  FormItens,
  PageTitle,
} from "../../pages/Secretary/styles";
import { anoLetivo } from "../../utils/YEARCONSTS";

export const SaveDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState(false);
  const [typeError, setTypeError] = useState("success");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [anexosSelecionados, setAnexosSelecionados] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [uniqueStudentNames, setUniqueStudentNames] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllStudents(anoLetivo)
      .then((res) => {
        const names = res.map((item) => `${item.AlunoID}-${item.Aluno}`);
        const uniqueNames = [...new Set(names)]; // Remove nomes duplicados
        setUniqueStudentNames(uniqueNames);
      })
      .then(() => setLoading(false));
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleStudentSelect = (event, newValue) => {
    setSelectedStudent(newValue);
  };

  const filteredStudents = uniqueStudentNames.filter((name) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleFileChange = (e) => {
    const files = e.target.files;
    setAnexosSelecionados([...anexosSelecionados, ...files]);
  };
  const resetFields = () => {
    setSelectedStudent(null);
    setAnexosSelecionados([]);
  };

  const sendFile = async () => {
    setLoading(true);
    const AlunoID = selectedStudent.split("-")[0];
    const Nome = selectedStudent.split("-")[1];
    const fData = new FormData();

    // Adicionar documentos
    anexosSelecionados.forEach((file) => {
      fData.append("documents", file);
    });

    fData.append("Nome", Nome);
    fData.append("AlunoID", AlunoID);
    const result = await sendDocuments(fData);
    if (result) {
      setErrorMessage("Documentos enviados com sucesso!");
      setTypeError("success");
      setError(true);
      resetFields();
      setLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };
  return (
    <Container>
      <ContainerBox>
        <PageTitle>Documentos</PageTitle>

        <Autocomplete
          onChange={handleStudentSelect}
          disablePortal
          options={filteredStudents}
          sx={{ width: "80%", margin: "10px auto" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Digite parte do nome do aluno para consulta"
              onChange={handleSearchChange}
            />
          )}
        />
        <FormItens encType="multipart/form-data">
          <AttachmentsArea>
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              style={{ backgroundColor: "#1E958C" }}
            >
              Inserir Documentos
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                multiple
              />
            </Button>
            <div>
              {anexosSelecionados.map((file, index) => (
                <p key={index}>{file.name}</p>
              ))}
            </div>
            <div></div>
          </AttachmentsArea>
          <Button
            onClick={() => {
              sendFile();
            }}
            startIcon={<SendIcon />}
            variant="contained"
            style={{ height: 70, width: "80%" }}
            disabled={
              selectedStudent === null || anexosSelecionados.length === 0
            }
          >
            Salvar documentos
          </Button>
        </FormItens>

        <Snackbar
          open={error}
          autoHideDuration={5000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={typeError}
            sx={{ width: "100%" }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        {loading && <Loading />}
      </ContainerBox>
    </Container>
  );
};
