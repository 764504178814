import React, { useContext, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import IconsBar from "../components/IconsBar/IconsBar";
import ActivityDiary from "../components/ActivityDiary";
import CardClassroom from "../components/CardClassroom";
import { getInfoTeacher } from "../api/autenticoAPI";
import { AuthContext } from "../contexts/Auth/AuthContext";

const Container = styled.div`
  text-align: center;
  width: 100%;
  min-height: 100vh;
`;

const ContentArea = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: 16px auto;
  padding: 0 16px;
  max-width: 1200px;
`;

const initialSelectedState = {
  classroom: { name: "8" },
  subject: { name: "quimica" },
};

const Diary = () => {
  const { user } = useContext(AuthContext);
  const [selected, setSelected] = useState(initialSelectedState);
  const [open, setOpen] = useState(false);
  const [classrooms, setClassrooms] = useState([]);

  const fetchTeacherInfo = useCallback(async () => {
    try {
      if (!user?.id) return;

      const response = await getInfoTeacher(user.id);
      setClassrooms(response);
    } catch (error) {
      console.error("Error fetching teacher info:", error);
      // Aqui você pode adicionar um tratamento de erro mais específico se necessário
    }
  }, [user?.id]);

  useEffect(() => {
    fetchTeacherInfo();
  }, [fetchTeacherInfo]);

  const handleClassroomSelection = useCallback((newSelection) => {
    setSelected(newSelection);
  }, []);

  const handleModalToggle = useCallback((state) => {
    setOpen(state);
  }, []);

  if (!user?.id) {
    return null; // ou um componente de loading/erro
  }

  return (
    <Container>
      <Header />
      <IconsBar />
      <ActivityDiary
        setOpen={handleModalToggle}
        open={open}
        selected={selected}
      />
      <ContentArea>
        {classrooms.map((classroom) => (
          <CardClassroom
            key={`${classroom.id}-${classroom.name}`}
            setSelected={handleClassroomSelection}
            setOpen={handleModalToggle}
            title={classroom}
            subjects={classroom.classes_subjects}
          />
        ))}
      </ContentArea>
    </Container>
  );
};

export default Diary;
