import styled from "styled-components";
export const Container = styled.div`
  margin-bottom: 20px;
`;

export const TitleArea = styled.div`
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const PageTitle = styled.span`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #1e958c;
  margin: 10px auto;
`;

export const SelectionArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SponteArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
  gap: 10px;
`;
export const WarningText = styled.p`
  color: #ab0505;
  text-align: center;
  width: 60%;
`;
export const SponteButtons = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const AnoLetivo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  gap: 10px;
`;
