import React from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;
const CaptionsArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;
const ButtonsArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
const Title = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  color: #004d93;
  font-size: 15px;
`;
const ClassName = styled.p`
  text-transform: uppercase;
  color: #136905;
  font-size: 12px;
`;

const RegistrationItem = ({ data }) => {
  return (
    <Container>
      <CaptionsArea>
        <Title>{data.Aluno}</Title>
        <ClassName>
          {data.NomeTurma} - {data.DataMatricula} - {data.ContratoID}
        </ClassName>
      </CaptionsArea>
      <ButtonsArea>{data.Situacao}</ButtonsArea>
    </Container>
  );
};

export default RegistrationItem;
