import React, { useState } from "react";
import { Container, InputsArea } from "./styles";
import { Alert, Button, Snackbar, TextField } from "@mui/material";
import { DataAccess } from "./DataAccess";
import { addRegister, getAccessData } from "../../api/autenticoAPI";
import { Loading } from "../Loading/Loading";

export const Register = () => {
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState("");
  const [users, setUsers] = useState([]);
  const [error, setError] = React.useState(false);
  const [typeError, setTypeError] = useState("success");
  const [errorMessage, setErrorMessage] = React.useState("");
  
  const handleRegister = () => {
    setLoading(true);
    addRegister(register).then((res) => {
      if (res.status) {
        setErrorMessage(res.message);
        setTypeError("success");
        setError(true);
        getAccessData(register).then((res) => setUsers(res));
        setLoading(false);
      } else {
        setTypeError("error");
        setErrorMessage(res.message);
        setError(true);
      }
      setLoading(false);
    });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };
  return (
    <Container>
      <InputsArea>
        <TextField
          value={register}
          onChange={({ target }) => setRegister(target.value)}
          label="Matrícula"
          variant="outlined"
          fullWidth
        />
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={() => handleRegister()}
        >
          Cadastrar
        </Button>
      </InputsArea>
      <Snackbar
        open={error}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={typeError}
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      {users.length > 0 && <DataAccess users={users} />}
      {loading && <Loading />}
    </Container>
  );
};
