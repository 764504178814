import React, { useContext, useEffect, useState, useCallback } from "react";
import {
  Container,
  ContentArea,
  StudentsList,
  TitlePage,
  SelectionArea,
} from "./styles";
import IconsBar from "../../components/IconsBar/IconsBar";
import Header from "../../components/Header";
import { SelectDate } from "../../components/SelectDate";
import { IntegranteItem } from "../../components/IntegranteItem/IntegranteItem";
import { getStudentsByClassroom } from "../../api/sponteAPI";
import { getIntegrateClasses } from "../../utils/YEARCONSTS";
import { ClassroomSelect } from "../../components/ClassroomSelect";
import { getRoutinesByDate } from "../../api/autenticoAPI";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { Loading } from "../../components/Loading/Loading";

export const Integrante = () => {
  const [date, setDate] = useState(new Date());
  const [students, setStudents] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [partialData, setPartialData] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useContext(AuthContext);

  const fetchStudents = useCallback(async (selectedClassroom) => {
    try {
      setLoading(true);
      const res = await getStudentsByClassroom(selectedClassroom);
      if (res) {
        setStudents(res.Integrantes);
      }
    } catch (error) {
      console.error("Erro ao buscar estudantes:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchRoutines = useCallback(async (selectedDate) => {
    try {
      const formattedDate = selectedDate.toISOString().split("T")[0];
      const res = await getRoutinesByDate(formattedDate);
      setPartialData(res);
    } catch (error) {
      console.error("Erro ao buscar rotinas:", error);
    }
  }, []);

  const updateRoutineList = useCallback(async () => {
    if (classrooms.length > 0) {
      await fetchStudents(classrooms[1]);
    }
    await fetchRoutines(date);
  }, [classrooms, date, fetchStudents, fetchRoutines]);

  useEffect(() => {
    updateRoutineList();
  }, [updateRoutineList, updateList]);

  return (
    <Container>
      <Header />
      <IconsBar />
      <ContentArea>
        <TitlePage>Rotinas do Integral</TitlePage>
        <SelectionArea>
          <SelectDate date={date} setDate={setDate} />
          <ClassroomSelect
            classrooms={classrooms}
            setClassrooms={setClassrooms}
            integrante={getIntegrateClasses(auth.user.unit)}
          />
        </SelectionArea>

        {loading && <Loading />}

        {classrooms.length > 0 && (
          <StudentsList>
            {students.length > 0 &&
              students.map((item) => {
                const studentPartialData = partialData.find(
                  (student) => student.idSponte === item.AlunoID
                );

                return (
                  <IntegranteItem
                    key={`student_${item.AlunoID}`}
                    student={item}
                    status={Boolean(studentPartialData)}
                    date={date}
                    editData={studentPartialData}
                    upadateList={updateList}
                    setUpdateList={setUpdateList}
                  />
                );
              })}
          </StudentsList>
        )}
      </ContentArea>
    </Container>
  );
};

export default Integrante;
