import * as React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function SelectSys({ anoLetivo, setAnoLetivo }) {
  const handleChange = (event) => {
    setAnoLetivo(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
      <InputLabel id="anoLetivo">Ano Letivo</InputLabel>
      <Select
        labelId="anoLetivo"
        value={anoLetivo}
        label="Ano Letivo"
        onChange={handleChange}
      >
        <MenuItem value={2024}>2024</MenuItem>
        <MenuItem value={2025}>2025</MenuItem>
      </Select>
    </FormControl>
  );
}
