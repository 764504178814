import React, { useEffect, useState, useRef } from "react";
import Header from "../components/Header";
import IconsBar from "../components/IconsBar/IconsBar";
import styled from "styled-components";
import { Button, Tabs, Tab, Box, Typography, TextField } from "@mui/material";
import {
  Create as CreateIcon,
  Send as SendIcon,
  Upload as UploadIcon,
} from "@mui/icons-material";
import { sendGraphDiary } from "../api/autenticoAPI";
import { ClassroomSelect } from "../components/ClassroomSelect";
import { useNavigate } from "react-router-dom";
import { getAllClassrooms } from "../api/sponteAPI";
import { anoLetivo } from "../utils/YEARCONSTS";
import AgendaForm from "../components/AgendaForm/AgendaForm";

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`agenda-tabpanel-${index}`}
    aria-labelledby={`agenda-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
  </div>
);

const GraphDiary = () => {
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [classrooms, setClassrooms] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    getAllClassrooms(anoLetivo).then((res) => setClassrooms(res));
  }, []);

  const sendFileGraphDiary = () => {
    if (!selectedDate) {
      alert("Por favor, selecione uma data para a agenda.");
      return;
    }

    // Ajusta a data para meio-dia do dia selecionado para evitar problemas de fuso horário
    const [year, month, day] = selectedDate.split("-");
    const formattedDate = `${year}-${month}-${day} 12:00:00`;

    const fData = new FormData();
    fData.append("file", selectedImage);
    fData.append("body", classrooms[0]);
    fData.append("type", "FILE");
    fData.append("classroom_id", classrooms[1]);
    fData.append("class_name", classrooms[0]);
    fData.append("date", formattedDate);

    sendGraphDiary(fData, "FILE");
    navigate("/autentico/stats");
    alert("Agenda enviada com sucesso!");
  };

  const handleImageChange = (e) => {
    if (e.target.files?.[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleGeneratedImage = (imageFile, date) => {
    setSelectedImage(imageFile);
    setSelectedDate(date);
    setActiveTab(1);
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Container>
      <Header />
      <IconsBar />
      <ContentArea>
        <h3 className="page-title">Agenda Diária Modo Gráfico</h3>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
          >
            <Tab
              icon={<CreateIcon />}
              iconPosition="start"
              label="Criar Agenda"
            />
            <Tab
              icon={<SendIcon />}
              iconPosition="start"
              label="Enviar Agenda"
            />
          </Tabs>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <AgendaForm onImageGenerate={handleGeneratedImage} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <SenderContainer>
            {selectedImage && (
              <img
                alt="Preview da Agenda"
                className="img-preview"
                src={URL.createObjectURL(selectedImage)}
              />
            )}

            <DateContainer>
              <TextField
                type="date"
                label="Data da Agenda"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </DateContainer>

            <ClassroomSelect
              classrooms={classrooms}
              setClassrooms={setClassrooms}
              anoLetivo={anoLetivo}
            />

            <FormItens encType="multipart/form-data">
              <div className="file-send">
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  {selectedImage
                    ? "Imagem atual:"
                    : "Selecione uma imagem ou gere uma na aba anterior"}
                </Typography>

                <FileUploadBox onClick={handleUploadClick}>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <UploadIcon sx={{ mr: 1 }} />
                  <span>
                    {selectedImage
                      ? selectedImage.name
                      : "Clique para selecionar um arquivo"}
                  </span>
                </FileUploadBox>

                <Button
                  onClick={sendFileGraphDiary}
                  variant="contained"
                  disabled={!selectedImage || !selectedDate}
                  startIcon={<SendIcon />}
                >
                  Enviar Agenda
                </Button>
              </div>
            </FormItens>
          </SenderContainer>
        </TabPanel>
      </ContentArea>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
`;

const ContentArea = styled.div`
  margin: 15px auto;
  background-color: #f0eeee;
  width: 100%;
  max-width: 800px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .page-title {
    margin: 15px 0;
    font-size: 25px;
    font-weight: bold;
    color: #004d93;
  }
  .img-preview {
    height: 200px;
    margin-bottom: 15px;
    width: 100%;
    object-fit: contain;
    border-radius: 20px;
  }
`;

const DateContainer = styled.div`
  width: 80%;
  margin: 0 auto 15px auto;

  .MuiTextField-root {
    background-color: white;
    border-radius: 4px;
  }
`;

const SenderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FormItens = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 15px;
  min-height: 180px;
  .file-send {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
`;

const FileUploadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px dashed #999;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #f5f5f5;
    border-color: #666;
  }

  span {
    color: #666;
  }
`;

export default GraphDiary;
