import { Alert } from "@material-ui/lab";
import ModalResultado from "../ModalResultado/ModalResultado";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  updateGradesByStudent,
  updateVisibilityByStudent,
} from "../../api/autenticoAPI";
import { TitleLegend } from "../TitleLegend";
import {
  InputGradeAC1,
  InputGradeAC2,
  InputMB,
  InputMediaAC1,
  InputMediaAC2,
  InputMPR,
  InputName,
  InputRM,
  ListStudents,
  StudentsGrades,
} from "./styles";
import { Checkbox, Tooltip } from "@mui/material";
import { AlertDialog } from "../AlertDialog";
import { getGradesBySubject } from "../../api/sponteAPI";

export const GradeItem = ({
  aluno,
  legend,
  tabIndex,
  last,
  TurmaID,
  period,
  DisciplinaID,
  nomeDisciplina,
  classGrades,
  selectAll,
  setSelectAll,
  updateStudentVisibility,
  repeatGrades,
  validate,
  setValidate,
}) => {
  const [nome, setNome] = useState(aluno.nome);
  const [AT1, setAT1] = useState("");
  const [AT2, setAT2] = useState("");
  const [AT3, setAT3] = useState("");
  const [AT4, setAT4] = useState("");
  const [AT5, setAT5] = useState("");
  const [AT6, setAT6] = useState("");
  const [AC1, setAC1] = useState("");
  const [AC2, setAC2] = useState("");
  const [MB, setMB] = useState("");
  const [RM, setRM] = useState("");
  const [MPR, setMPR] = useState("");
  const inputRef = useRef(null);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [openResultado, setOpenResultado] = useState(false);
  const [value, setValue] = useState("");
  const [currentSetter, setCurrentSetter] = useState("");
  const [gradeSponte, setGradeSponte] = useState("");

  const clearMedia = useCallback((setter) => {
    setter("");
  }, []);

  const calcPartialMedia = useCallback(() => {
    const ac1Value = parseFloat((AC1 && AC1.replace(",", ".")) || 0);
    const ac2Value = parseFloat((AC2 && AC2.replace(",", ".")) || 0);
    const partialMedia = (ac1Value + ac2Value) / 2;
    const formattedMedia = partialMedia.toLocaleString("pt-BR", {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
    setMB(formattedMedia);
  }, [AC1, AC2]);

  const updateGrades = useCallback(
    async (ac1, ac2, mb) => {
      updateGradesByStudent(
        aluno.AlunoID,
        TurmaID,
        DisciplinaID,
        period,
        AT1,
        AT2,
        AT3,
        AT4,
        AT5,
        AT6,
        ac1,
        ac2,
        RM,
        mb,
        nomeDisciplina
      );
    },
    [
      aluno.AlunoID,
      TurmaID,
      DisciplinaID,
      period,
      AT1,
      AT2,
      AT3,
      AT4,
      AT5,
      AT6,
      RM,
      nomeDisciplina,
    ]
  );

  const calcMPR = useCallback(() => {
    clearMedia(setMPR);
    if (RM) {
      setMPR(MB > RM ? MB : RM);
      updateGrades(AC1, AC2);
    }
  }, [RM, MB, AC1, AC2, clearMedia, updateGrades]);

  const validateGrades = useCallback(() => {
    getGradesBySubject(aluno.AlunoID, TurmaID, DisciplinaID).then((res) =>
      setGradeSponte(res[`Nota${period}`])
    );
  }, [aluno.AlunoID, TurmaID, DisciplinaID, period]);

  useEffect(() => {
    if (validate) {
      validateGrades();
    }
  }, [validate, validateGrades]);

  useEffect(() => {
    if (updateStudentVisibility !== 0) {
      updateVisibilityByStudent(
        aluno.AlunoID,
        TurmaID,
        DisciplinaID,
        period,
        visible
      );
    }
  }, [
    updateStudentVisibility,
    aluno.AlunoID,
    TurmaID,
    DisciplinaID,
    period,
    visible,
  ]);

  useEffect(() => {
    setVisible(selectAll);
  }, [selectAll]);

  useEffect(() => {
    if (Array.isArray(classGrades.grades)) {
      const studentGrades = classGrades.grades.filter(
        (item) => item.AlunoID === aluno.AlunoID
      );
      if (studentGrades[0]) {
        setAT1(studentGrades[0].at1);
        setAT2(studentGrades[0].at2);
        setAT3(studentGrades[0].at3);
        setAT4(studentGrades[0].at4);
        setAT5(studentGrades[0].at5);
        setAT6(studentGrades[0].at6);
        setAC1(studentGrades[0].ac1);
        setAC2(studentGrades[0].ac2);
        setRM(studentGrades[0].rm);
        setVisible(studentGrades[0].visibility);
      }
    }
    return () => {
      setValidate(false);
    };
  }, [aluno.AlunoID, classGrades.grades, setValidate]);

  useEffect(() => {
    calcPartialMedia();
  }, [calcPartialMedia]);

  useEffect(() => {
    calcMPR();
  }, [calcMPR]);

  // Resto das funções do componente
  const addDecimalIfNeeded = (value) => {
    if (!value.includes(",")) {
      return value + ",0";
    }
    return value;
  };

  const handleChange = (event, setter) => {
    clearMedia(setAC1);
    clearMedia(setAC2);
    inputRef.current = document.activeElement;
    const inputValue = event.target.value;
    const isValidInput = /^[0-9,]*$/.test(inputValue);
    if (isValidInput) {
      setter(inputValue);
    } else {
      setter("");
    }
  };

  const handleBlur = () => {
    if (AT1) setAT1(addDecimalIfNeeded(AT1));
    if (AT2) setAT2(addDecimalIfNeeded(AT2));
    if (AT3) setAT3(addDecimalIfNeeded(AT3));
    if (AT4) setAT4(addDecimalIfNeeded(AT4));
    if (AT5) setAT5(addDecimalIfNeeded(AT5));
    if (AT6) setAT6(addDecimalIfNeeded(AT6));
    if (RM) setRM(addDecimalIfNeeded(RM));

    let gradeAC1 = "";
    let gradeAC2 = "";
    setError(false);

    if (AT1 || AT2) {
      gradeAC1 = (
        parseFloat((AT1 && AT1.replace(",", ".")) || 0) +
        parseFloat((AT2 && AT2.replace(",", ".")) || 0)
      ).toFixed(1);
      if (gradeAC1 > 10) {
        if (inputRef.current) {
          setError(true);
          inputRef.current.focus();
          inputRef.current.style.background = "red";
          return;
        }
      }
      setAC1(gradeAC1.replace(".", ","));
      if (inputRef.current) {
        inputRef.current.style.background = "";
      }
    }

    if (AT3 || AT4 || AT5 || AT6) {
      gradeAC2 = (
        parseFloat((AT3 && AT3.replace(",", ".")) || 0) +
        parseFloat((AT4 && AT4.replace(",", ".")) || 0) +
        parseFloat((AT5 && AT5.replace(",", ".")) || 0) +
        parseFloat((AT6 && AT6.replace(",", ".")) || 0)
      ).toFixed(1);
      if (gradeAC2 > 10) {
        if (inputRef.current) {
          setError(true);
          inputRef.current.focus();
          inputRef.current.style.background = "red";
          return;
        }
      }
      setAC2(gradeAC2.replace(".", ","));
      if (inputRef.current) {
        inputRef.current.style.background = "";
      }
    }

    updateGrades(
      gradeAC1.replace(".", ","),
      gradeAC2.replace(".", ","),
      (Number(gradeAC1) + Number(gradeAC2)) / 2
    );
  };

  const handleKeyPress = (event, setter) => {
    if (event.key === "Enter") {
      setOpen(true);
      setValue(event.target.value);
      setCurrentSetter(setter);
    }
  };

  return (
    <StudentsGrades>
      <ListStudents>
        {legend && (
          <TitleLegend
            TurmaID={TurmaID}
            period={period}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        )}
        {error && (
          <Alert severity="error" variant="filled">
            A soma das notas não pode ultrapassar o valor "10"
          </Alert>
        )}
        <Checkbox checked={visible} onChange={() => setVisible(!visible)} />
        {!validate ? (
          <Tooltip
            title="Clique para ver o boletim desse aluno"
            arrow
            placement="right"
          >
            <InputName
              className="finish"
              type="text"
              value={nome}
              onChange={(event) => handleChange(event, setNome)}
              onClick={() => setOpenResultado(true)}
              onBlur={handleBlur}
              readOnly
            />
          </Tooltip>
        ) : (
          <InputName
            className="finish"
            type="text"
            value={nome + `   -   (${gradeSponte})`}
            onChange={(event) => handleChange(event, setNome)}
            onBlur={handleBlur}
            readOnly
            style={{
              backgroundColor: validate && gradeSponte !== MB ? "red" : "",
            }}
          />
        )}

        <InputGradeAC1
          className="grade-input"
          type="text"
          value={AT1}
          onChange={(event) => handleChange(event, setAT1)}
          onBlur={handleBlur}
          onKeyPress={(event) => handleKeyPress(event, "at1")}
          tabIndex={tabIndex}
        />
        <InputGradeAC1
          type="text"
          value={AT2}
          onChange={(event) => handleChange(event, setAT2)}
          onBlur={handleBlur}
          onKeyPress={(event) => handleKeyPress(event, "at2")}
          tabIndex={tabIndex + last}
        />
        <InputGradeAC2
          type="text"
          value={AT3}
          onChange={(event) => handleChange(event, setAT3)}
          onBlur={handleBlur}
          onKeyPress={(event) => handleKeyPress(event, "at3")}
          tabIndex={tabIndex + 2 * last}
        />
        <InputGradeAC2
          type="text"
          value={AT4}
          onChange={(event) => handleChange(event, setAT4)}
          onBlur={handleBlur}
          onKeyPress={(event) => handleKeyPress(event, "at4")}
          tabIndex={tabIndex + 3 * last}
        />
        <InputGradeAC2
          type="text"
          value={AT5}
          onChange={(event) => handleChange(event, setAT5)}
          onBlur={handleBlur}
          onKeyPress={(event) => handleKeyPress(event, "at5")}
          tabIndex={tabIndex + 4 * last}
        />
        <InputGradeAC2
          type="text"
          value={AT6}
          onChange={(event) => handleChange(event, setAT6)}
          onBlur={handleBlur}
          onKeyPress={(event) => handleKeyPress(event, "at6")}
          tabIndex={tabIndex + 5 * last}
        />
        <InputMediaAC1
          type="text"
          value={AC1}
          onChange={(event) => handleChange(event, setAC1)}
          onBlur={handleBlur}
          style={{
            fontWeight: "bold",
            backgroundColor:
              AC1 && parseFloat(AC1.replace(",", ".")) > 10 ? "red" : "inherit",
          }}
          readOnly
        />
        <InputMediaAC2
          type="text"
          value={AC2}
          onChange={(event) => handleChange(event, setAC2)}
          onBlur={handleBlur}
          style={{
            fontWeight: "bold",
            backgroundColor:
              AC2 && parseFloat(AC2.replace(",", ".")) > 10 ? "red" : "inherit",
          }}
          readOnly
        />
        <InputMB
          type="text"
          value={MB}
          onChange={(event) => handleChange(event, setMB)}
          onBlur={handleBlur}
          style={{
            fontWeight: "bold",
            color: MB && parseFloat(MB.replace(",", ".")) < 7 ? "red" : "blue",
          }}
          readOnly
        />
        <InputRM
          type="text"
          value={RM}
          onChange={(event) => handleChange(event, setRM)}
          onBlur={handleBlur}
          tabIndex={tabIndex + 9 * last}
        />
        <InputMPR
          type="text"
          value={MPR}
          onChange={(event) => handleChange(event, setMPR)}
          onBlur={handleBlur}
          style={{
            fontWeight: "bold",
            color:
              MPR && parseFloat(MPR.replace(",", ".")) < 7 ? "red" : "blue",
          }}
          readOnly
        />
      </ListStudents>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title="Alterar notas em massa"
        body={"Você deseja repetir essa nota para todos os alunos?"}
        handleConfirm={async () => {
          try {
            repeatGrades(value, currentSetter);
          } catch (error) {
            console.log(error);
          }
        }}
      />
      <ModalResultado
        open={openResultado}
        setOpen={setOpenResultado}
        aluno={aluno}
        TurmaID={TurmaID}
      />
    </StudentsGrades>
  );
};
