import React, { useRef, useCallback } from "react";
import { Modal, Box, Button } from "@mui/material";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PrintIcon from "@mui/icons-material/Print";
import logo from "./logo.png";
import { getUnidadeData } from "../../utils/YEARCONSTS";

const PrintableReport = ({
  open,
  onClose,
  aluno,
  subjects,
  formattedGrades,
  finalExams,
  studentStatus,
  unidade = "CAMPINA GRANDE",
}) => {
  const reportRef = useRef(null);
  const unidadeData = getUnidadeData(unidade);

  const getGradeForSubject = useCallback((disciplinaId, bimester, grades) => {
    const period = `period${bimester}`;
    const grade = grades[period]?.find((g) => g.DisciplinaID === disciplinaId);
    return grade?.average || "-";
  }, []);

  const calculateFinalAverage = useCallback(
    (disciplinaId, grades) => {
      const bimesters = [1, 2, 3, 4];
      const validGrades = bimesters
        .map((b) => getGradeForSubject(disciplinaId, b, grades))
        .filter((g) => g !== "-");

      if (validGrades.length === 0) return "-";

      const sum = validGrades.reduce(
        (acc, grade) => acc + parseFloat(grade.replace(",", ".")),
        0
      );
      return (sum / validGrades.length).toFixed(1).replace(".", ",");
    },
    [getGradeForSubject]
  );

  const handleGeneratePDF = useCallback(async () => {
    if (!reportRef.current) return;

    try {
      const canvas = await html2canvas(reportRef.current, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });

      const pdf = new jsPDF({
        format: "a4",
        unit: "mm",
      });

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      const pdfBlob = pdf.output("bloburl");
      window.open(pdfBlob, "_blank");
    } catch (error) {
      console.error("Erro ao gerar PDF:", error);
    }
  }, []);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="printable-report">
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "90vh",
          width: "160mm",
          maxWidth: "95vw",
          bgcolor: "background.paper",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          p: 4,
          overflowY: "auto",
          "&:focus": {
            outline: "none",
          },
        }}
      >
        <div ref={reportRef} style={{ background: "#fff", padding: "20px" }}>
          {/* Cabeçalho com informações da escola */}
          <div style={{ textAlign: "center", marginBottom: "20px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "12px",
                gap: "12px",
              }}
            >
              <img src={logo} alt="Logo Autêntico" style={{ height: "80px" }} />
              <div>
                <h2
                  style={{
                    fontSize: "18px",
                    margin: "0",
                    color: "#1a237e",
                    fontWeight: "600",
                  }}
                >
                  {unidadeData.nome}
                </h2>
                <p
                  style={{
                    fontSize: "12px",
                    margin: "4px 0",
                    color: "#666",
                  }}
                >
                  {unidadeData.endereco}
                </p>
                <p
                  style={{
                    fontSize: "12px",
                    margin: "2px 0",
                    color: "#666",
                  }}
                >
                  {unidadeData.website} • {unidadeData.telefone}
                </p>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "#1a237e",
                padding: "8px",
                borderRadius: "6px",
                color: "white",
                marginBottom: "10px",
              }}
            >
              <h1
                style={{
                  fontSize: "16px",
                  margin: 0,
                  fontWeight: "600",
                  letterSpacing: "0.5px",
                }}
              >
                BOLETIM ESCOLAR
              </h1>
            </div>
          </div>

          {/* Informações do aluno */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              marginBottom: "10px",
              fontSize: "15px",
              padding: "10px",
              backgroundColor: "#f8f9fa",
              borderRadius: "6px",
            }}
          >
            <div>
              <strong style={{ color: "#1a237e" }}>Aluno:</strong>{" "}
              {aluno.nome.split("-")[1]}
            </div>
            <div>
              <strong style={{ color: "#1a237e" }}>Ano Letivo: </strong>
              {unidadeData.anoLetivo}
            </div>
          </div>

          {/* Tabela de notas */}
          <div
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "6px",
              overflow: "hidden",
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                fontSize: "14px",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#f8f9fa" }}>
                  <th
                    style={{
                      padding: "10px",
                      textAlign: "left",
                      color: "#1a237e",
                      borderBottom: "2px solid #e0e0e0",
                      width: "25%",
                    }}
                  >
                    Disciplinas
                  </th>
                  {[1, 2, 3, 4].map((bim) => (
                    <th
                      key={bim}
                      style={{
                        padding: "16px",
                        textAlign: "center",
                        color: "#1a237e",
                        borderBottom: "2px solid #e0e0e0",
                      }}
                    >
                      {bim}º Bimestre
                    </th>
                  ))}
                  <th
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      color: "#1a237e",
                      borderBottom: "2px solid #e0e0e0",
                    }}
                  >
                    MF
                  </th>
                  <th
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      color: "#1a237e",
                      borderBottom: "2px solid #e0e0e0",
                    }}
                  >
                    EF
                  </th>
                  <th
                    style={{
                      padding: "16px",
                      textAlign: "center",
                      color: "#1a237e",
                      borderBottom: "2px solid #e0e0e0",
                    }}
                  >
                    Situação
                  </th>
                </tr>
              </thead>
              <tbody>
                {subjects.map((subject, index) => (
                  <tr
                    key={subject.DisciplinaID}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#fafafa",
                    }}
                  >
                    <td
                      style={{
                        padding: "8px 10px",
                        borderBottom: "1px solid #e0e0e0",
                      }}
                    >
                      {subject.Nome}
                    </td>
                    {[1, 2, 3, 4].map((bim) => (
                      <td
                        key={bim}
                        style={{
                          padding: "8px",
                          textAlign: "center",
                          borderBottom: "1px solid #e0e0e0",
                        }}
                      >
                        {getGradeForSubject(
                          subject.DisciplinaID,
                          bim,
                          formattedGrades
                        )}
                      </td>
                    ))}
                    <td
                      style={{
                        padding: "14px",
                        textAlign: "center",
                        borderBottom: "1px solid #e0e0e0",
                        fontWeight: "500",
                      }}
                    >
                      {calculateFinalAverage(
                        subject.DisciplinaID,
                        formattedGrades
                      )}
                    </td>
                    <td
                      style={{
                        padding: "14px",
                        textAlign: "center",
                        borderBottom: "1px solid #e0e0e0",
                      }}
                    >
                      {finalExams[subject.DisciplinaID] || "-"}
                    </td>
                    <td
                      style={{
                        padding: "14px",
                        textAlign: "center",
                        borderBottom: "1px solid #e0e0e0",
                        color:
                          studentStatus[subject.DisciplinaID] === "APROVADO"
                            ? "#2e7d32"
                            : studentStatus[subject.DisciplinaID] === "EM EXAME"
                            ? "#ed6c02"
                            : "#666",
                      }}
                    >
                      {studentStatus[subject.DisciplinaID] || "CURSANDO"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Legenda */}
          <div
            style={{
              fontSize: "13px",
              marginTop: "24px",
              color: "#666",
              display: "flex",
              gap: "20px",
            }}
          >
            <span>
              <strong>MF</strong> - Média Final
            </span>
            <span>
              <strong>EF</strong> - Exame Final
            </span>
          </div>
        </div>

        {/* Botão para gerar PDF */}
        <Box sx={{ mt: 3, textAlign: "center" }}>
          <Button
            variant="contained"
            startIcon={<PrintIcon />}
            onClick={handleGeneratePDF}
            sx={{
              bgcolor: "#1a237e",
              "&:hover": {
                bgcolor: "#0d47a1",
              },
              borderRadius: "8px",
              textTransform: "none",
              padding: "10px 24px",
              fontSize: "15px",
            }}
          >
            Visualizar PDF
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PrintableReport;
