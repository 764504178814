import React, { useState, useContext } from "react";
import {
  Card,
  CardContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  TextField,
  Tab,
  Tabs,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CoffeeIcon from "@mui/icons-material/Coffee";
import BakeryDiningIcon from "@mui/icons-material/BakeryDining";
import { AuthContext } from "../../contexts/Auth/AuthContext";

const MenuManager = () => {
  const { user } = useContext(AuthContext);
  const [selectedMeal, setSelectedMeal] = useState(0);
  const [newItemName, setNewItemName] = useState("");

  const [menuItems, setMenuItems] = useState({
    "CAMPINA GRANDE": {
      breakfast: {
        foods: [
          { id: 1, name: "Achocolatado" },
          { id: 2, name: "Banana" },
        ],
      },
      lunch: {
        foods: [
          { id: 1, name: "Abacaxi" },
          { id: 2, name: "Almondegas" },
        ],
      },
      snack: {
        foods: [
          { id: 1, name: "Achocolatado" },
          { id: 2, name: "Arroz de leite" },
        ],
      },
    },
    PATOS: {
      breakfast: {
        foods: [
          { id: 1, name: "Bolo de chocolate" },
          { id: 2, name: "Bolo de cenoura com calda (opcional)" },
        ],
      },
      lunch: {
        foods: [
          { id: 1, name: "Almôndegas" },
          { id: 2, name: "Arroz com cenoura e repolho" },
        ],
      },
      snack: {
        foods: [
          { id: 1, name: "Bolo de laranja com calda" },
          { id: 2, name: "Brownie de batata doce" },
        ],
      },
    },
  });

  const getMealType = (index) => {
    switch (index) {
      case 0:
        return "breakfast";
      case 1:
        return "lunch";
      case 2:
        return "snack";
      default:
        return "breakfast";
    }
  };

  const handleAddItem = () => {
    if (!newItemName.trim()) return;

    setMenuItems((prev) => {
      const newItems = { ...prev };
      const mealType = getMealType(selectedMeal);
      const currentFoods = newItems[user.unit][mealType].foods;
      const newId =
        currentFoods.length > 0
          ? Math.max(...currentFoods.map((item) => item.id)) + 1
          : 1;

      newItems[user.unit][mealType].foods.push({
        id: newId,
        name: newItemName.trim(),
      });

      return newItems;
    });

    setNewItemName("");
  };

  const handleRemoveItem = (itemId) => {
    setMenuItems((prev) => {
      const newItems = { ...prev };
      const mealType = getMealType(selectedMeal);
      newItems[user.unit][mealType].foods = newItems[user.unit][
        mealType
      ].foods.filter((item) => item.id !== itemId);
      return newItems;
    });
  };

  const handleSave = () => {
    console.log(
      `Dados do cardápio da unidade ${user.unit} para salvar:`,
      menuItems[user.unit]
    );
  };

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: "800px",
        margin: "0 auto",
        boxShadow: "none",
      }}
    >
      <CardContent sx={{ padding: "24px" }}>
        {/* Header */}
        <div
          style={{
            marginBottom: "24px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
              marginBottom: "8px",
            }}
          >
            <RestaurantIcon sx={{ color: "#1B5E20", fontSize: "28px" }} />
            <h1
              style={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#1B5E20",
                margin: 0,
              }}
            >
              Gerenciador de Cardápio
            </h1>
          </div>
          <p style={{ margin: 0, color: "#666", fontSize: "14px" }}>
            Gerencie os itens do cardápio para cada refeição
          </p>
          <p
            style={{ margin: "8px 0 0 0", fontWeight: "500", color: "#1B5E20" }}
          >
            {user.unit}
          </p>
        </div>

        {/* Navigation Bar */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "24px",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Tabs
            value={selectedMeal}
            onChange={(_, newValue) => setSelectedMeal(newValue)}
            centered
            sx={{
              flex: 1,
              "& .MuiTab-root": {
                textTransform: "none",
                fontSize: "14px",
                fontWeight: "500",
                minHeight: "48px",
                color: "#666",
                "&.Mui-selected": {
                  color: "#1B5E20",
                },
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#1B5E20",
              },
            }}
          >
            <Tab
              icon={<CoffeeIcon />}
              label="Café da Manhã"
              iconPosition="start"
            />
            <Tab
              icon={<RestaurantIcon />}
              label="Almoço"
              iconPosition="start"
            />
            <Tab
              icon={<BakeryDiningIcon />}
              label="Lanche"
              iconPosition="start"
            />
          </Tabs>
          <Button
            variant="contained"
            onClick={handleSave}
            startIcon={<SaveIcon />}
            sx={{
              backgroundColor: "#1B5E20",
              "&:hover": {
                backgroundColor: "#154c1a",
              },
              textTransform: "none",
              padding: "8px 24px",
              borderRadius: "4px",
              minWidth: "180px",
            }}
          >
            Salvar Alterações
          </Button>
        </div>

        {/* Input Area */}
        <div style={{ display: "flex", gap: "12px", marginBottom: "24px" }}>
          <TextField
            fullWidth
            size="medium"
            placeholder="Digite o nome do item..."
            value={newItemName}
            onChange={(e) => setNewItemName(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleAddItem()}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px",
              },
            }}
          />
          <IconButton
            onClick={handleAddItem}
            disabled={!newItemName.trim()}
            sx={{
              backgroundColor: newItemName.trim() ? "#1B5E20" : "#e0e0e0",
              color: "white",
              borderRadius: "4px",
              padding: "8px",
              "&:hover": {
                backgroundColor: newItemName.trim() ? "#154c1a" : "#e0e0e0",
              },
            }}
          >
            <AddIcon />
          </IconButton>
        </div>

        {/* Items List */}
        <List
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: "4px",
            border: "1px solid #e0e0e0",
          }}
        >
          {menuItems[user.unit][getMealType(selectedMeal)].foods.map(
            (item, index) => (
              <ListItem
                key={item.id}
                sx={{
                  borderBottom:
                    index !==
                    menuItems[user.unit][getMealType(selectedMeal)].foods
                      .length -
                      1
                      ? "1px solid #e0e0e0"
                      : "none",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
              >
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    style: {
                      color: "#333",
                      fontSize: "14px",
                    },
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleRemoveItem(item.id)}
                    sx={{ color: "#666" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default MenuManager;
