import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import moment from "moment";
import { SelectDate } from "../SelectDate";
import { ItemDiaryStats } from "./ItemDiaryStats";
import { getDiariesByDate } from "../../api/autenticoAPI";

const Container = styled.div`
  /* Adicione estilos se necessário */
`;

export const Diaries = ({ date, setDate, focused }) => {
  const [diariesList, setDiariesList] = useState([]);

  const updateDiariesList = useCallback(async () => {
    try {
      const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
      const response = await getDiariesByDate(formattedDate);
      setDiariesList(response.diaries);
    } catch (error) {
      console.error("Erro ao atualizar lista de diários:", error);
      setDiariesList([]);
    }
  }, [date]);

  useEffect(() => {
    updateDiariesList();
  }, [date, focused, updateDiariesList]);

  return (
    <Container>
      <SelectDate date={date} setDate={setDate} />
      {diariesList.length > 0 &&
        diariesList.map((diary) => (
          <ItemDiaryStats
            key={diary.id}
            class_name={diary.class_name}
            url={diary.url}
            id={diary.id}
            updateDiariesList={updateDiariesList}
          />
        ))}
    </Container>
  );
};

export default Diaries;
