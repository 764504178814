// Tabela de conversão de notas
export const tabelaNotas = {
  10.0: 10.0,
  10.1: 9.9,
  10.2: 9.9,
  10.3: 9.8,
  10.4: 9.8,
  10.5: 9.7,
  10.6: 9.7,
  10.7: 9.6,
  10.8: 9.6,
  10.9: 9.5,
  11.0: 9.5,
  11.1: 9.4,
  11.2: 9.4,
  11.3: 9.3,
  11.4: 9.3,
  11.5: 9.2,
  11.6: 9.2,
  11.7: 9.1,
  11.8: 9.1,
  11.9: 9.0,
  12.0: 9.0,
  12.1: 8.9,
  12.2: 8.9,
  12.3: 8.8,
  12.4: 8.8,
  12.5: 8.7,
  12.6: 8.7,
  12.7: 8.6,
  12.8: 8.6,
  12.9: 8.5,
  13.0: 8.5,
  13.1: 8.4,
  13.2: 8.4,
  13.3: 8.3,
  13.4: 8.3,
  13.5: 8.2,
  13.6: 8.2,
  13.7: 8.1,
  13.8: 8.1,
  13.9: 8.0,
  14.0: 8.0,
  14.1: 7.9,
  14.2: 7.9,
  14.3: 7.8,
  14.4: 7.8,
  14.5: 7.7,
  14.6: 7.7,
  14.7: 7.6,
  14.8: 7.6,
  14.9: 7.5,
  15.0: 7.5,
  15.1: 7.4,
  15.2: 7.4,
  15.3: 7.3,
  15.4: 7.3,
  15.5: 7.2,
  15.6: 7.2,
  15.7: 7.1,
  15.8: 7.1,
  15.9: 7.0,
  16.0: 7.0,
  16.1: 6.9,
  16.2: 6.9,
  16.3: 6.8,
  16.4: 6.8,
  16.5: 6.7,
  16.6: 6.7,
  16.7: 6.6,
  16.8: 6.6,
  16.9: 6.5,
  17.0: 6.5,
  17.1: 6.4,
  17.2: 6.4,
  17.3: 6.3,
  17.4: 6.3,
  17.5: 6.2,
  17.6: 6.2,
  17.7: 6.1,
  17.8: 6.1,
  17.9: 6.0,
  18.0: 6.0,
  18.1: 5.9,
  18.2: 5.9,
  18.3: 5.8,
  18.4: 5.8,
  18.5: 5.7,
  18.6: 5.7,
  18.7: 5.6,
  18.8: 5.6,
  18.9: 5.5,
  19.0: 5.5,
  19.1: 5.4,
  19.2: 5.4,
  19.3: 5.3,
  19.4: 5.3,
  19.5: 5.2,
  19.6: 5.2,
  19.7: 5.1,
  19.8: 5.1,
  19.9: 5.0,
  20.0: 5.0,
  20.1: 5.0,
  20.2: 4.9,
  20.3: 4.9,
  20.4: 4.8,
  20.5: 4.8,
  20.6: 4.7,
  20.7: 4.6,
  20.8: 4.6,
  20.9: 4.6,
  21.0: 4.5,
  21.1: 4.4,
  21.2: 4.4,
  21.3: 4.3,
  21.4: 4.3,
  21.5: 4.2,
  21.6: 4.4,
  21.7: 4.4,
  21.8: 4.3,
  21.9: 4.3,
  22.0: 4.3,
  22.1: 4.2,
  22.2: 4.2,
  22.3: 4.1,
  22.4: 4.1,
  22.5: 4.1,
  22.6: 4.0,
  22.7: 4.0,
  22.8: 4.0,
  22.9: 3.9,
  23.0: 3.9,
  23.1: 3.9,
  23.2: 3.8,
  23.3: 3.8,
  23.4: 3.7,
  23.5: 3.7,
  23.6: 3.7,
  23.7: 3.6,
  23.8: 3.6,
  23.9: 3.5,
  24.0: 3.5,
  24.1: 3.5,
  24.2: 3.4,
  24.3: 3.4,
  24.4: 3.4,
  24.5: 3.3,
  24.6: 3.3,
  24.7: 3.2,
  24.8: 3.2,
  24.9: 3.2,
  25.0: 3.1,
  25.1: 3.1,
  25.2: 3.1,
  25.3: 3.0,
  25.4: 3.0,
  25.5: 2.9,
  25.6: 2.9,
  25.7: 2.9,
  25.8: 2.8,
  25.9: 2.8,
  26.0: 2.8,
  26.1: 2.7,
  26.2: 2.7,
  26.3: 2.6,
  26.4: 2.6,
  26.5: 2.5,
  26.6: 2.5,
  26.7: 2.5,
  26.8: 2.4,
  26.9: 2.4,
  27.0: 2.3,
  27.1: 2.3,
  27.2: 2.2,
  27.3: 2.2,
  27.4: 2.2,
  27.5: 2.2,
  27.6: 2.2,
  27.7: 2.1,
  27.8: 2.1,
  27.9: 2.0,
};

// Funções utilitárias
export const calculateAverage = (ac1, ac2) => {
  // Garantir que os valores sejam strings
  const nota1 = String(ac1 || '');
  const nota2 = String(ac2 || '');
  
  if (!nota1 || !nota2) return "-";
  
  // Normaliza os números, trocando vírgula por ponto
  const num1 = parseFloat(nota1.replace(",", "."));
  const num2 = parseFloat(nota2.replace(",", "."));
  
  if (isNaN(num1) || isNaN(num2)) return "-";
  
  const media = ((num1 + num2) / 2).toFixed(1);
  return media.replace(".", ",");
};

export const getGradeForSubject = (disciplinaId, period, formattedGrades) => {
  if (!disciplinaId) return "-";
  
  const grade = formattedGrades[`period${period}`]?.find(
    (g) => String(g.DisciplinaID) === String(disciplinaId)
  );
  return grade?.average || "-";
};

export const calculateFinalAverage = (disciplinaId, formattedGrades) => {
  if (!disciplinaId) return "-";
  
  const grades = [1, 2, 3, 4].map((period) => {
    const grade = getGradeForSubject(String(disciplinaId), period, formattedGrades);
    return grade === "-" ? null : parseFloat(grade.replace(",", "."));
  });

  if (grades.some((grade) => grade === null)) return "-";
  
  const sum = grades.reduce((acc, curr) => acc + curr, 0);
  return (sum / 4).toFixed(1).replace(".", ",");
};

export const determineStatus = (mediaFinal, exameFinal) => {
  const mf = parseFloat(mediaFinal.replace(",", "."));
  if (isNaN(mf)) return "CURSANDO";

  if (mf >= 7) return "APROVADO";

  if (!exameFinal) return "EM EXAME";

  const ef = parseFloat(exameFinal.replace(",", "."));
  if (isNaN(ef)) return "EM EXAME";

  const pontosMF = parseFloat(mf) * 4;
  const pontoArredondado = Math.round(pontosMF * 10) / 10;
  const notaNecessaria =
    tabelaNotas[pontoArredondado] ||
    (pontosMF <= 10.0 ? 10.0 : pontosMF >= 27.9 ? 2.0 : null);

  return ef >= notaNecessaria ? "APROVADO" : "REPROVADO";
};

export const validateGrade = (value) => {
  if (!value || value === "-" || value === "") return "";
  const numValue = parseFloat(value.replace(",", "."));
  if (isNaN(numValue)) return "";
  return numValue.toFixed(1);
};

export const calcularNotaNecessaria = (pontosTotais, mf) => {
  if (pontosTotais === "-" || mf >= 7) return "-";
  const pts = parseFloat(pontosTotais);

  const pontoArredondado = Math.round(pts * 10) / 10;
  const nota = tabelaNotas[pontoArredondado];

  if (!nota) return "-";

  const notaStr = nota.toString();
  return notaStr.includes(".") ? notaStr.replace(".", ",") : notaStr + ",0";
};
