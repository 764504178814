import React, { useEffect, useState, useCallback, useContext } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PrintableReport from "./PrintableReport";
import PrintIcon from "@mui/icons-material/Print";

import { getSubjects } from "../../api/sponteAPI";
import {
  getGradesByStudent,
  getFinalResults,
  saveFinalResults,
} from "../../api/autenticoAPI";
import {
  modalStyle,
  tableStyles,
  thStyles,
  tdStyles,
  Legends,
  Student,
  tableContainerStyles,
} from "./styles";
import {
  calculateAverage,
  getGradeForSubject,
  calculateFinalAverage,
  determineStatus,
  validateGrade,
  calcularNotaNecessaria,
} from "./utils";
import { AuthContext } from "../../contexts/Auth/AuthContext";

const ModalResultado = ({ open, setOpen, aluno, TurmaID }) => {
  const [subjects, setSubjects] = useState([]);
  const [showPrintable, setShowPrintable] = useState(false);
  const [formattedGrades, setFormattedGrades] = useState({
    period1: [],
    period2: [],
    period3: [],
    period4: [],
  });
  const [finalExams, setFinalExams] = useState({});
  const [studentStatus, setStudentStatus] = useState({});
  const [visibility, setVisibility] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [initialData, setInitialData] = useState({
    exams: {},
    status: {},
    visibility: {},
  });
  const auth = useContext(AuthContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    console.log(auth.unit);
  }, [auth.unit]);

  const loadData = useCallback(async () => {
    try {
      if (!open) return;

      const [subjects, grades, finalResults] = await Promise.all([
        getSubjects(TurmaID),
        getGradesByStudent(aluno.AlunoID, TurmaID),
        getFinalResults(aluno.AlunoID, TurmaID),
      ]);

      setSubjects(
        Array.isArray(subjects?.Disciplinas) ? subjects.Disciplinas : []
      );

      const formatted = {
        period1: [],
        period2: [],
        period3: [],
        period4: [],
      };

      if (grades) {
        ["period1", "period2", "period3", "period4"].forEach((period) => {
          if (grades[period]) {
            formatted[period] = grades[period].map((grade) => ({
              DisciplinaID: String(grade.DisciplinaID),
              average: calculateAverage(
                String(grade.ac1 || ""),
                String(grade.ac2 || "")
              ),
            }));
          }
        });
      }

      setFormattedGrades(formatted);

      if (finalResults?.disciplinas) {
        const exams = {};
        const status = {};
        const visibilityState = {};

        finalResults.disciplinas.forEach((discipline) => {
          const disciplinaId = String(discipline.DisciplinaID);
          if (discipline.exameFinal && discipline.exameFinal !== "0") {
            exams[disciplinaId] = String(discipline.exameFinal);
          }
          status[disciplinaId] = discipline.situacao || "CURSANDO";
          visibilityState[disciplinaId] = discipline.visibilidade || false;
        });

        setFinalExams(exams);
        setStudentStatus(status);
        setVisibility(visibilityState);
        setInitialData({ exams, status, visibility: visibilityState });
        setHasChanges(false);
      }
    } catch (error) {
      console.error("Erro ao carregar dados:", error);
      setSnackbar({
        open: true,
        message: "Erro ao carregar dados. Por favor, tente novamente.",
        severity: "error",
      });
    }
  }, [open, aluno.AlunoID, TurmaID]);

  const handleFinalExamChange = (disciplinaId, value) => {
    setFinalExams((prev) => {
      const newExams = { ...prev };
      if (!value || value === "0") {
        newExams[disciplinaId] = "";
      } else {
        newExams[disciplinaId] = value;
      }

      const mediaFinal = calculateFinalAverage(disciplinaId, formattedGrades);
      const newStatus = determineStatus(mediaFinal, value);
      setStudentStatus((prevStatus) => ({
        ...prevStatus,
        [disciplinaId]: newStatus,
      }));

      checkForChanges(newExams, studentStatus, visibility);
      return newExams;
    });
  };

  const handleVisibilityToggle = (disciplinaId) => {
    setVisibility((prev) => {
      const newVisibility = {
        ...prev,
        [disciplinaId]: !prev[disciplinaId],
      };
      checkForChanges(finalExams, studentStatus, newVisibility);
      return newVisibility;
    });
  };

  const handleToggleAllVisibility = () => {
    setVisibility((prev) => {
      const allVisible = subjects.every(
        (subject) => prev[subject.DisciplinaID] === true
      );

      const newVisibility = {};
      subjects.forEach((subject) => {
        newVisibility[subject.DisciplinaID] = !allVisible;
      });

      checkForChanges(finalExams, studentStatus, newVisibility);
      return newVisibility;
    });
  };
  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!open) return;

    subjects.forEach((subject) => {
      const mediaFinal = calculateFinalAverage(
        subject.DisciplinaID,
        formattedGrades
      );
      const exameFinal = finalExams[subject.DisciplinaID];
      const newStatus = determineStatus(mediaFinal, exameFinal);

      setStudentStatus((prev) => ({
        ...prev,
        [subject.DisciplinaID]: newStatus,
      }));
    });
  }, [formattedGrades, finalExams, subjects, open]);

  const handleStatusChange = (disciplinaId, value) => {
    setStudentStatus((prev) => {
      const newStatus = { ...prev, [disciplinaId]: value };
      checkForChanges(finalExams, newStatus, visibility);
      return newStatus;
    });
  };

  const checkForChanges = (currentExams, currentStatus, currentVisibility) => {
    const examsChanged =
      JSON.stringify(currentExams) !== JSON.stringify(initialData.exams);
    const statusChanged =
      JSON.stringify(currentStatus) !== JSON.stringify(initialData.status);
    const visibilityChanged =
      JSON.stringify(currentVisibility) !==
      JSON.stringify(initialData.visibility);
    setHasChanges(examsChanged || statusChanged || visibilityChanged);
  };

  const handleSaveResults = async () => {
    try {
      setLoading(true);

      const gradesData = subjects.map((subject) => {
        const mediaFinal = calculateFinalAverage(
          subject.DisciplinaID,
          formattedGrades
        );

        return {
          disciplinaId: subject.DisciplinaID.toString(),
          mediaFinal: validateGrade(mediaFinal),
          exameFinal: finalExams[subject.DisciplinaID] || "",
          situacao: studentStatus[subject.DisciplinaID] || "CURSANDO",
          visibilidade: visibility[subject.DisciplinaID] || false,
        };
      });

      const response = await saveFinalResults(
        aluno.AlunoID,
        TurmaID,
        gradesData
      );

      if (response?.success) {
        setSnackbar({
          open: true,
          message: "Resultados salvos com sucesso!",
          severity: "success",
        });
        await loadData();
      } else {
        throw new Error(response?.message || "Erro ao salvar resultados");
      }
    } catch (error) {
      console.error("Erro ao salvar:", error);
      setSnackbar({
        open: true,
        message: `Erro ao salvar os resultados: ${error.message}`,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-resultado"
        sx={{
          bgcolor: "rgba(0, 0, 0, 0.8)",
        }}
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              flexShrink: 0,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: "#1f2937",
              }}
            >
              Boletim Geral
            </Typography>
            <Button
              variant="contained"
              onClick={handleSaveResults}
              disabled={loading || !hasChanges}
              sx={{
                bgcolor: "#2563eb",
                "&:hover": {
                  bgcolor: "#1d4ed8",
                },
                "&:disabled": {
                  bgcolor: "#94a3b8",
                },
              }}
            >
              {loading ? "Salvando..." : "Salvar Alterações"}
            </Button>

            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              onClick={() => setShowPrintable(true)}
              sx={{
                marginLeft: 2,
              }}
            >
              Versão para Impressão
            </Button>
          </Box>

          <Box sx={Legends}>
            <Typography sx={Student}>{aluno.nome.split("-")[1]}</Typography>
          </Box>

          <Box sx={tableContainerStyles}>
            <table style={tableStyles}>
              <thead>
                <tr>
                  <th style={thStyles}>Disciplina</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>1º B</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>2º B</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>3º B</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>4º B</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>MF</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>PO</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>NE</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>EF</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>Situação</th>
                  <th style={{ ...thStyles, textAlign: "center" }}>
                    Visibilidade
                    <IconButton
                      onClick={handleToggleAllVisibility}
                      size="small"
                      sx={{
                        padding: "4px",
                        marginLeft: "4px",
                        backgroundColor: "rgba(0,0,0,0.04)",
                        "&:hover": {
                          backgroundColor: "rgba(0,0,0,0.08)",
                        },
                      }}
                    >
                      {subjects.every(
                        (subject) => visibility[subject.DisciplinaID] === true
                      ) ? (
                        <VisibilityIcon sx={{ fontSize: "1rem" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ fontSize: "1rem" }} />
                      )}
                    </IconButton>
                  </th>
                </tr>
              </thead>
              <tbody>
                {subjects.map((subject, index) => {
                  const mediaFinal = calculateFinalAverage(
                    String(subject?.DisciplinaID),
                    formattedGrades
                  );
                  const mf = parseFloat(mediaFinal.replace(",", "."));
                  const pontos = isNaN(mf) ? "-" : (mf * 4).toFixed(1);
                  const notaNecessaria = calcularNotaNecessaria(pontos, mf);

                  return (
                    <tr
                      key={subject?.Nome || index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#f9fafb",
                      }}
                    >
                      <td style={{ ...tdStyles, fontWeight: 500 }}>
                        {subject?.Nome || "-"}
                      </td>
                      <td style={{ ...tdStyles, textAlign: "center" }}>
                        {getGradeForSubject(
                          subject?.DisciplinaID,
                          1,
                          formattedGrades
                        )}
                      </td>
                      <td style={{ ...tdStyles, textAlign: "center" }}>
                        {getGradeForSubject(
                          subject?.DisciplinaID,
                          2,
                          formattedGrades
                        )}
                      </td>
                      <td style={{ ...tdStyles, textAlign: "center" }}>
                        {getGradeForSubject(
                          subject?.DisciplinaID,
                          3,
                          formattedGrades
                        )}
                      </td>
                      <td style={{ ...tdStyles, textAlign: "center" }}>
                        {getGradeForSubject(
                          subject?.DisciplinaID,
                          4,
                          formattedGrades
                        )}
                      </td>
                      <td
                        style={{
                          ...tdStyles,
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        {mediaFinal}
                      </td>
                      <td
                        style={{
                          ...tdStyles,
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        {pontos}
                      </td>
                      <td
                        style={{
                          ...tdStyles,
                          textAlign: "center",
                          fontWeight: 500,
                        }}
                      >
                        {notaNecessaria}
                      </td>
                      <td style={{ ...tdStyles, textAlign: "center" }}>
                        <TextField
                          size="small"
                          value={finalExams[subject?.DisciplinaID] || ""}
                          onChange={(e) =>
                            handleFinalExamChange(
                              subject?.DisciplinaID,
                              e.target.value
                            )
                          }
                          inputProps={{
                            style: {
                              textAlign: "center",
                              fontSize: "0.75rem",
                              padding: "4px 8px",
                            },
                            placeholder: "-",
                          }}
                          sx={{
                            width: "80px",
                            "& .MuiInputBase-root": {
                              height: "28px",
                            },
                          }}
                        />
                      </td>
                      <td style={{ ...tdStyles, textAlign: "center" }}>
                        <Select
                          size="small"
                          value={
                            studentStatus[subject?.DisciplinaID] || "CURSANDO"
                          }
                          onChange={(e) =>
                            handleStatusChange(
                              subject?.DisciplinaID,
                              e.target.value
                            )
                          }
                          sx={{
                            width: "140px",
                            fontSize: "0.75rem",
                            height: "28px",
                            "& .MuiSelect-select": {
                              padding: "4px 8px",
                            },
                          }}
                        >
                          <MenuItem
                            sx={{ fontSize: "0.75rem" }}
                            value="CURSANDO"
                          >
                            CURSANDO
                          </MenuItem>
                          <MenuItem
                            sx={{ fontSize: "0.75rem" }}
                            value="APROVADO"
                          >
                            APROVADO
                          </MenuItem>
                          <MenuItem
                            sx={{ fontSize: "0.75rem" }}
                            value="REPROVADO"
                          >
                            REPROVADO
                          </MenuItem>
                          <MenuItem
                            sx={{ fontSize: "0.75rem" }}
                            value="EM EXAME"
                          >
                            EM EXAME
                          </MenuItem>
                        </Select>
                      </td>
                      <td style={{ ...tdStyles, textAlign: "center" }}>
                        <IconButton
                          onClick={() =>
                            handleVisibilityToggle(subject?.DisciplinaID)
                          }
                          size="small"
                          sx={{ padding: "4px" }}
                        >
                          {visibility[subject?.DisciplinaID] ? (
                            <VisibilityIcon sx={{ fontSize: "1.1rem" }} />
                          ) : (
                            <VisibilityOffIcon sx={{ fontSize: "1.1rem" }} />
                          )}
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <PrintableReport
        open={showPrintable}
        onClose={() => setShowPrintable(false)}
        aluno={aluno}
        subjects={subjects}
        formattedGrades={formattedGrades}
        finalExams={finalExams}
        studentStatus={studentStatus}
        unidade={auth.user.unit}
      />
    </>
  );
};

export default ModalResultado;
