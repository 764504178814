import React, { useEffect, useState, useCallback } from "react";
import { Container, DocumentLink, LinkArea } from "./styles";
import { Autocomplete, TextField } from "@mui/material";
import { Loading } from "../Loading/Loading";
import {
  ActionsArea,
  FormItens,
  PageTitle,
} from "../../pages/Announcements/styles";
import { getAllStudents, getStudentByID } from "../../api/sponteAPI";
import { anoLetivo } from "../../utils/YEARCONSTS";

export const SearchDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [uniqueStudentNames, setUniqueStudentNames] = useState([]);
  const [showFolder, setShowFolder] = useState(null);

  const loadStudents = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAllStudents(anoLetivo);
      const names = res.map((item) => `${item.AlunoID}-${item.Aluno}`);
      const uniqueNames = [...new Set(names)];
      setUniqueStudentNames(uniqueNames);
    } catch (error) {
      console.error("Erro ao carregar estudantes:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadStudents();
  }, [loadStudents]);

  const searchDocumentsByStudent = useCallback(async (studentValue) => {
    if (studentValue) {
      try {
        setLoading(true);
        const AlunoID = studentValue.split("-")[0];
        const folderLink = await getStudentByID(AlunoID);
        if (folderLink) {
          setShowFolder(folderLink);
        }
      } catch (error) {
        console.error("Erro ao buscar documentos:", error);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const handleSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const handleStudentSelect = useCallback(
    (event, newValue) => {
      setShowFolder(null);
      searchDocumentsByStudent(newValue);
    },
    [searchDocumentsByStudent]
  );

  const filteredStudents = uniqueStudentNames.filter((name) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Container>
      <PageTitle>Consulta</PageTitle>

      <Autocomplete
        onChange={handleStudentSelect}
        disablePortal
        options={filteredStudents}
        sx={{ width: "80%", margin: "10px auto" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Digite parte do nome do aluno para consulta"
            onChange={handleSearchChange}
          />
        )}
      />
      <FormItens encType="multipart/form-data">
        <ActionsArea>
          {showFolder && (
            <LinkArea>
              <p>Clique na imagem para acessar</p>
              <DocumentLink
                href={showFolder}
                target="_blank"
                rel="noopener noreferrer"
                className="link-drive"
              >
                <img
                  src="https://www.gstatic.com/images/branding/product/2x/drive_2020q4_48dp.png"
                  alt="Google Drive Folder Icon"
                />
              </DocumentLink>
            </LinkArea>
          )}
        </ActionsArea>
      </FormItens>
      {loading && <Loading />}
    </Container>
  );
};

export default SearchDocuments;
