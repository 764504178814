import React, { useState } from "react";
import Header from "../../components/Header";
import IconsBar from "../../components/IconsBar/IconsBar";
import { Container, ContentArea } from "./styles";
import { AlertMessages } from "../../components/AlertMessages";
import { SecretaryTabs } from "../../components/SecretaryTabs/SecretaryTabs";

const Secretary = () => {
  const [alertState, setAlertState] = useState({
    show: false,
    type: "success",
    message: "",
  });

  return (
    <Container>
      <Header />
      <IconsBar />
      <ContentArea>
        <SecretaryTabs />
      </ContentArea>
      <AlertMessages
        show={alertState.show}
        typeMessage={alertState.type}
        contentMessage={alertState.message}
        setShow={(show) => setAlertState((prev) => ({ ...prev, show }))}
      />
    </Container>
  );
};

export default Secretary;
