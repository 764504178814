import { createApiInstance } from "./InstanceAPI";

export const addRegister = async (registration) => {
  const api = createApiInstance();
  const { data } = await api.post("/users/signin", {
    registration: [registration],
  });
  return data;
};
export const addRoutine = async (routine) => {
  const api = createApiInstance();
  const { data } = await api.post("/integrantes/new", routine);
  return data;
};
export const editRoutine = async (routine) => {
  const api = createApiInstance();
  const { data } = await api.post("/integrantes/edit", routine);
  return data;
};
export const getAppStudents = async () => {
  const api = createApiInstance();
  const { data } = await api.get("/users");
  return data.users.filter((item) => {
    if (item.type === "STUDENT") {
      return item;
    }
    return null;
  });
};
export const getAccessData = async (register) => {
  try {
    console.log("Dados sendo enviados:", { registration: register });

    if (!register) {
      throw new Error("Registro não pode ser vazio");
    }

    const api = createApiInstance();
    const response = await api.post("/users/info", { registration: register });

    return response.data;
  } catch (error) {
    if (error.response) {
      // O servidor respondeu com um status de erro
      console.error("Erro do servidor:", {
        status: error.response.status,
        data: error.response.data,
      });
    } else if (error.request) {
      // A requisição foi feita mas não houve resposta
      console.error("Sem resposta do servidor:", error.request);
    } else {
      // Erro na configuração da requisição
      console.error("Erro na requisição:", error.message);
    }
    throw error;
  }
};
export const getAllTokens = async () => {
  const api = createApiInstance();
  const { data } = await api.get("/users");
  return data.users.filter((item) => {
    if (item.tokenNotification !== null) {
      return item;
    }
    return null;
  });
};
export const getAppResponsables = async () => {
  const api = createApiInstance();
  const { data } = await api.get("/users");
  return data.users.filter((item) => {
    if (item.type === "RESPONSABLE") {
      return item;
    }
    return null;
  });
};
export const sendResponsableNotification = async (notification) => {
  const api = createApiInstance();
  const { data } = await api.post("/notifications/send", notification);
  return data;
};
export const sendAnnouncements = async (announcements, type) => {
  const api = createApiInstance();
  if (type === "TEXT") {
    const { data } = await api.post("/announcements/send", announcements);
    return data;
  } else {
    const { data } = await api.post("/announcements/upload", announcements);
    return data;
  }
};
export const getAllNotifications = async () => {
  const api = createApiInstance();
  const { data } = await api.get("/notifications/list");
  return data;
};
export const getAllAnnouncements = async () => {
  const api = createApiInstance();
  const { data } = await api.get("/announcements/list");
  return data;
};
export const getInfoTeacher = async (id) => {
  const api = createApiInstance();
  const { data } = await api.post("/teachers/one", { id });
  return data;
};
export const getSubjectsByTeacher = async (teacher_id, classroom_id) => {
  const api = createApiInstance();
  const { data } = await api.post("/classes/one", { teacher_id, classroom_id });
  return data;
};
export const addActivity = async (activity) => {
  const api = createApiInstance();
  const { data } = await api.post("/activities/new", activity);
  return data;
};
export const sendDiary = async (diary) => {
  const api = createApiInstance();
  const { data } = await api.delete("/diaries/new", diary);
  return data;
};
export const deleteDiary = async (diaryId) => {
  const api = createApiInstance();
  const { data } = await api.delete("/diaries/delete", {
    data: { id: diaryId },
  });
  return data;
};
export const sendGraphDiary = async (diary, type) => {
  const api = createApiInstance();
  if (type === "TEXT") {
    const { data } = await api.post("/diaries/send", diary);
    return data;
  } else {
    const { data } = await api.post("/diaries/upload", diary);
    return data;
  }
};
export const sendOneSignalAnnouncements = async (form) => {
  const api = createApiInstance();
  const { data } = await api.post("/announcements/upload", form);
  return data;
};
export const getDiariesByDate = async (date) => {
  const api = createApiInstance();
  const { data } = await api.post("/diaries/date", { date });
  return data;
};
export const getGradesByClassroom = async (TurmaID, DisciplinaID, period) => {
  const api = createApiInstance();
  const { data } = await api.post("/grades/list/classroom", {
    TurmaID,
    DisciplinaID,
    period,
  });
  return data;
};
export const updateVisibilityByStudent = async (
  AlunoID,
  TurmaID,
  DisciplinaID,
  period,
  visibility
) => {
  const api = createApiInstance();
  const { data } = await api.post("/grades/new", {
    AlunoID,
    TurmaID,
    DisciplinaID,
    period,
    visibility,
  });
  return data;
};

export const updateGradesByStudent = async (
  AlunoID,
  TurmaID,
  DisciplinaID,
  period,
  at1,
  at2,
  at3,
  at4,
  at5,
  at6,
  ac1,
  ac2,
  rm,
  mb,
  nomeDisciplina,
  visibility
) => {
  const api = createApiInstance();
  const { data } = await api.post("/grades/new", {
    AlunoID,
    TurmaID,
    DisciplinaID,
    period,
    at1,
    at2,
    at3,
    at4,
    at5,
    at6,
    ac1,
    ac2,
    rm,
    mb,
    nomeDisciplina,
    visibility,
  });
  return data;
};
export const updateAllGrades = async (
  AlunoID,
  TurmaID,
  DisciplinaID,
  period,
  values
) => {
  const api = createApiInstance();
  const { data } = await api.post("/grades/new", {
    AlunoID,
    TurmaID,
    DisciplinaID,
    period,
    ...values,
  });
  return data;
};

export const setCaptions = async (captions) => {
  const api = createApiInstance();
  const { data } = await api.post("/grades/captions", captions);
  return data;
};
export const getCaptions = async (TurmaID, period) => {
  const api = createApiInstance();
  const { data } = await api.post("/grades/captions/list", { TurmaID, period });
  return data;
};
export const getTeachers = async () => {
  const api = createApiInstance();
  const { data } = await api.get("/access/teachers");
  return data;
};
export const getTeachersAllocations = async (
  teacherID,
  DisciplinaID,
  TurmaID
) => {
  const api = createApiInstance();
  const { data } = await api.post("/allocation/list", {
    teacherID,
    DisciplinaID,
    TurmaID,
  });
  return data;
};
export const allocateTeacher = async (
  teacherID,
  teacherName,
  TurmaID,
  turmaName,
  DisciplinaID,
  disciplinaName
) => {
  const api = createApiInstance();
  const { data } = await api.post("/allocation/new", {
    teacherID,
    teacherName,
    TurmaID,
    turmaName,
    DisciplinaID,
    disciplinaName,
  });
  return data;
};
export const deAllocateTeacher = async (teacherID, TurmaID, DisciplinaID) => {
  const api = createApiInstance();
  const { data } = await api.post("/allocation/remove", {
    teacherID,
    TurmaID,
    DisciplinaID,
  });
  return data;
};
export const getRoutinesByDate = async (date) => {
  const api = createApiInstance();
  const { data } = await api.post("/integrantes/date", { date });
  return data.integrantes;
};
export const deleteAnnouncements = async (announcementId) => {
  const api = createApiInstance();
  const { data } = await api.delete("/announcements/delete", {
    data: { id: announcementId },
  });
  return data;
};
export const setUserAccess = async (user) => {
  const api = createApiInstance();
  const { data } = await api.post("/access/new", user);
  return data;
};
export const sendDocuments = async (documents) => {
  const api = createApiInstance();
  const { data } = await api.post("/documents/upload", documents);
  return data;
};

export const getUsersByClass = async (classrooms) => {
  const api = createApiInstance();
  const { data } = await api.post("/announcements/users", {
    turmaIds: classrooms,
  });
  console.log(data);
  return data;
};

export const getGradesByStudent = async (AlunoID, TurmaID) => {
  const api = createApiInstance();
  const { data } = await api.post("/grades/boletim", { AlunoID, TurmaID });
  return data;
};

export const saveFinalResults = async (AlunoID, TurmaID, grades) => {
  const api = createApiInstance();
  const { data } = await api.post("/grades/final/add", {
    AlunoID,
    TurmaID,
    grades,
  });
  return data;
};

export const getFinalResults = async (AlunoID, TurmaID) => {
  const api = createApiInstance();
  const { data } = await api.post("/grades/final/list", { AlunoID, TurmaID });
  return data;
};
