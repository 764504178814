import React, { useEffect, useState, useCallback, useContext } from "react";
import styled from "styled-components";
import { Checkbox } from "@mui/material";

import { getCaptions } from "../api/autenticoAPI";
import EditableText from "./EditableText";
import { AuthContext } from "../contexts/Auth/AuthContext";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NameTitle = styled.span`
  padding-left: 10px;
  width: 400px;
  font-weight: bold;
`;

const CommonTitle = styled.span`
  text-align: center;
  width: 60px;
  font-weight: bold;
`;

export const TitleLegend = ({ TurmaID, period, selectAll, setSelectAll }) => {
  const { user } = useContext(AuthContext);
  const [dbCaptions, setDbCaptions] = useState({
    TurmaID,
    period,
    at1: "AT1",
    at2: "AT2",
    at3: "AT3",
    at4: "AT4",
    at5: "AT5",
    at6: "AT6",
  });
  const [loading, setLoading] = useState(true);

  const readOnly = !["admin", "coordinator"].includes(user.level);

  const loadCaptions = useCallback(async () => {
    try {
      const response = await getCaptions(TurmaID, period);
      if (response) {
        setDbCaptions(response);
      }
    } catch (error) {
      console.error("Erro ao carregar legendas:", error);
    } finally {
      setLoading(false);
    }
  }, [TurmaID, period]);

  useEffect(() => {
    loadCaptions();
  }, [loadCaptions]);

  const handleSelectAllChange = useCallback(() => {
    setSelectAll(!selectAll);
  }, [selectAll, setSelectAll]);

  if (loading) {
    return null;
  }

  return (
    <Container>
      <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
      <NameTitle>Alunos</NameTitle>
      {["at1", "at2", "at3", "at4", "at5", "at6"].map((controller) => (
        <EditableText
          key={controller}
          controller={controller}
          defaultTitle={dbCaptions[controller]}
          dbCaptions={dbCaptions}
          setDbCaptions={setDbCaptions}
          readOnly={readOnly}
        />
      ))}
      {["AC1", "AC2", "Média", "RB", "MPR"].map((title) => (
        <CommonTitle key={title}>{title}</CommonTitle>
      ))}
    </Container>
  );
};

export default TitleLegend;
