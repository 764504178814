import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getAllStudents, getStudentByName } from "../../api/sponteAPI";
import { getAccessData } from "../../api/autenticoAPI";
import { Container } from "./styles";
import { DataAccess } from "./DataAccess";
import { Loading } from "../Loading/Loading";
import { anoLetivo } from "../../utils/YEARCONSTS";

export const SearchRegister = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [uniqueStudentNames, setUniqueStudentNames] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [dataInfo, setDataInfo] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllStudents(anoLetivo)
      .then((res) => {
        const names = res.map((item) => item.Aluno);
        const uniqueNames = [...new Set(names)];
        setUniqueStudentNames(uniqueNames);
      })
      .catch((error) => {
        console.error("Erro ao buscar estudantes:", error);
        setError("Erro ao carregar lista de estudantes");
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (selectedStudent) {
      setLoading(true);
      setError(null);

      getStudentByName(selectedStudent)
        .then((res) => {
          if (!res) {
            throw new Error("Nenhum dado do estudante encontrado");
          }
          console.log("Dados do estudante:", res); // Log para debug
          return getAccessData(res);
        })
        .then((res) => {
          console.log("Dados de acesso:", res); // Log para debug
          setDataInfo(res);
        })
        .catch((error) => {
          console.error("Erro na busca:", error);
          setError("Erro ao buscar dados do estudante");
          setDataInfo([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedStudent]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredStudents = uniqueStudentNames.filter((name) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleStudentSelect = (event, newValue) => {
    setSelectedStudent(newValue);
  };

  return (
    <Container>
      <Autocomplete
        onChange={handleStudentSelect}
        disablePortal
        options={filteredStudents}
        sx={{ width: "50%", margin: "10px auto" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Digite parte do nome do aluno para consulta"
            onChange={handleSearchChange}
            error={!!error}
            helperText={error}
          />
        )}
      />
      {error && (
        <div style={{ color: "red", textAlign: "center", margin: "10px" }}>
          {error}
        </div>
      )}
      {dataInfo.length > 0 && <DataAccess users={dataInfo} />}
      {loading && <Loading />}
    </Container>
  );
};
